import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Flex, Text, Grid, Button } from '@mantine/core';
import { useDisclosure } from "@mantine/hooks";
import { useBreakpointValue, useToast } from "@chakra-ui/react";
import { PodcastEpisode } from "../../models/PodcastEpisode";
import { SearchLogo } from "../../assets/SearchIcon";
import {EpisodeCard, SimpleEpisodeCard} from "./episode-card";
import { ShowCardSkeleton } from "../skeletons";
import { usePodcastLibrary } from "../../context/PodcastLibraryContext";
import { usePodcastLocalization } from "../../context/PodcastLocalizationContext";
import { useUser } from "../../context/UserContext";
import { GrUpdate } from "react-icons/gr";
import { useEpisodes, useFetchRss } from "../../hooks/usePodcastLibraryQueries";
import {LocalizationProgressFilter} from "../../pages/library/Podcast-Dashboard";
import {TranscriptionState} from "../../models/Localization";

interface EpisodeModuleProps {
    podcastId: string;
    progressFilter: LocalizationProgressFilter

}

export const EpisodeModule: React.FC<EpisodeModuleProps> = React.memo(({ podcastId, progressFilter }) => {
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId);
    const { mutate: fetchRss  } = useFetchRss();
    const { episodesConfiguration, setEpisodesConfiguration } = usePodcastLocalization();
    const user = useUser();
    const [searchTerm, setSearchTerm] = useState('');
    const [displayCheck, setDisplayCheck] = useState<"hidden" | "visible">('hidden');
    const [displayStyle, setDisplayStyle] = useState<"grid" | "list">('grid');
    const [currentSortingParam, setCurrentSortingParam] = useState<'title' | 'state'>('title')

    const [checkAll, setCheckAll] = useState<boolean>(false);
    const toast = useToast();

    const dynamicPageSize = useBreakpointValue({ base: 2, md: 4, lg: 6, xl: 6 }) || 6;

    const episodes = useMemo(() => podcastEpisodesData || [], [podcastEpisodesData]);

    const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const selectAllEpisodes = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newConfiguration = new Map(episodesConfiguration);
        episodes.forEach(episode => {
            if (e.target.checked) {
                newConfiguration.set(episode.id, { episode });
            } else {
                newConfiguration.delete(episode.id);
            }
        });
        setEpisodesConfiguration(newConfiguration);
        setCheckAll(e.target.checked);
    }, [episodes, episodesConfiguration, setEpisodesConfiguration]);

    const filteredEpisodes = useMemo(() => {
        return episodes.filter((episode) => {
            const { title } = episode;
            const normalizedSearchTerm = searchTerm.toLowerCase();
            switch (progressFilter) {
                case LocalizationProgressFilter.ALL:
                    return title.toLowerCase().includes(normalizedSearchTerm);
                case LocalizationProgressFilter.TRANSCRIPTION_IN_PROGRESS: {
                    return episode.transcription.state !== TranscriptionState.NOT_CREATED && episode.transcription.state !== TranscriptionState.COMPLETED

                }
                case LocalizationProgressFilter.TRANSCRIPTION_COMPLETED:
                    return episode.transcription.state === TranscriptionState.COMPLETED;
                case LocalizationProgressFilter.TRANSCRIPTION_NOT_STARTED: {
                    return episode.transcription.state === TranscriptionState.NOT_CREATED;
                }
                case LocalizationProgressFilter.TRANSLATION_IN_PROGRESS: {
                    return episode.translation.processing.length > 0;
                }
                case LocalizationProgressFilter.TRANSLATION_COMPLETED: {
                    return episode.translation.processing.length === 0;
                }
                case LocalizationProgressFilter.TRANSLATION_NOT_STARTED: {
                    return episode.translation.processing.length === 0 && episode.translation.completed.length === 0;
                }

                case LocalizationProgressFilter.DUBBING_IN_PROGRESS: {
                    return episode.dubbing.processing.length > 0;
                }
                case LocalizationProgressFilter.DUBBING_COMPLETED: {
                    return episode.dubbing.processing.length === 0;
                }
                case LocalizationProgressFilter.DUBBING_NOT_STARTED: {
                    return episode.dubbing.processing.length === 0 && episode.dubbing.completed.length === 0;
                }

            }
        });
    }, [episodes, searchTerm, progressFilter]);

    const fetchRSSFeed = useCallback(() => {
        const podcast = episodes[0]?.podcast;
        if (podcast) {
            fetchRss({ rss_feed: podcast.rss_feed, publisher: user, podcastId });
        }
    }, [episodes, user, podcastId, fetchRss]);

    if (episodesLoading) {
        return (
            <Grid w="100%" gutter="24px">
                {[...Array(dynamicPageSize)].map((_, index) => (
                    <Grid.Col span={6} key={index}>
                        <ShowCardSkeleton />
                    </Grid.Col>
                ))}
            </Grid>
        );
    }

    if (episodeError) {
        return <Text>Error loading episodes: {episodeError.message}</Text>;
    }

    return (
        <Flex
            direction="column"
            align="flex-start"
            gap="md"
            p="12px"
            w="100%"
            h={'100%'}
            style={{overflowY: 'auto' }}
        >
            <Flex w="100%" direction="row" align="center" justify="space-between">
                <SearchLogo onType={handleSearchInputChange} onOpenWidth="100%" />
            </Flex>
            <Grid w="100%" h={'100%'} gutter="36px" style={{overflowY: 'auto' }}>
                {filteredEpisodes.map((episode: PodcastEpisode) => (
                    <Grid.Col span={{ base: 12, sm: 6 }} key={`${episode.id}`}>
                        <SimpleEpisodeCard episode={episode}/>
                    </Grid.Col>
                ))}
            </Grid>
        </Flex>
    );
});
