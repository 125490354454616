import {AppShell, Badge, Box, Burger, Flex, FlexProps, Group, Image, Paper, Text} from '@mantine/core';
import {AppLogo} from "../assets/AppLogo";
import React, {ReactText, useEffect, useMemo, useState} from "react";
import {IconType} from "react-icons";
import {FiHome, FiLogOut, FiShoppingCart} from "react-icons/fi";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAuth} from "../context/AuthContext";
import CartPanel from "../components/cart/cart-panel";
import { useDisclosure as useChakraDisclosure } from "@chakra-ui/react";
import { useDisclosure as useMantineDisclosure } from '@mantine/hooks';
import {useLibraryNavigation} from "../context/LibraryNavigationContext";
import TranscriptionBreadcrumbs from "../studio/transcription-breadcrumbs";
import {TranscriptionSession} from "../models/Localization";
import {EpisodesIcon} from "../assets/EpisodesIcon";
import {removePTags} from "../utils/text-utils";
import {YoutubeChannelInfoModule} from "../components/youtube/youtube-channel-module";
import PodcastOverviewModule from "../sidebars/podcast/podcast-overview-module";
import PodcastLibrarySidebar from "../sidebars/podcast/podcast-library-sidebar";
import {IconRadio} from "@tabler/icons-react";
import {MdSpaceDashboard} from "react-icons/md";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import {Podcast} from "../models/Podcast";
import TranscriptionTaskSidebar from "../sidebars/localization/transcription-task-sidebar";
import PodcastLibraryEpisodeSidebar from "../components/episode/podcast-library-episode-sidebar";
import {useCart} from "../hooks/useCartQueries";


interface LinkItemProps {
    name: string;
    icon: IconType;
}

interface Page {
    children: React.ReactNode | React.ReactNode[],
}

const data = [
    { link: '', label: 'Library', icon: FiHome },
    { link: '', label: 'Cart', icon: FiShoppingCart },
    {link: '', label: 'Logout', icon: FiLogOut},
];
export function PublishersShell({children}: Page) {
    const libraryNavigation: any = useLibraryNavigation();
    const [opened, {toggle}] = useMantineDisclosure();
    const [mobileOpened, { toggle: toggleMobile }] = useMantineDisclosure();
    const [desktopOpened, { toggle: toggleDesktop }] = useMantineDisclosure(true);
    const [active, setActive] = useState('Podcasts');
    const auth: any = useAuth(); // Assuming you have a useAuth hook
    const { isOpen: isOpenCart, onOpen: onOpenCart, onClose: onCloseCart } = useChakraDisclosure();
    const navigate = useNavigate();
    const location = useLocation();
    const {sessionId}: any = useParams()
    const [session, setSession] = useState<TranscriptionSession>()
    const [podcast, setPodcast] = useState<any>(null)
    const [asideWidth, setAsideWidth] = useState(0);
    const { data: cart = [], isLoading: cartLoading } = useCart()




    useEffect(() => {
        if (libraryNavigation.studioSession) {
            console.log('Setting session');
            setSession(libraryNavigation.studioSession)
        } else {
            console.log('No session');
        }
    }, [libraryNavigation.studioSession]);

    useEffect(() => {
        if (libraryNavigation.currentPodcast) {
            setPodcast(libraryNavigation.currentPodcast)
        }
    }, [libraryNavigation.currentPodcast]);


    const links = data.map((item) => {
        const isActive = item.label === active;

        const handleClick = () => {
            switch (item.label) {
                case 'Logout':
                    auth.logout();
                    break;
                case 'Cart':
                    navigate('/cart')
                    break;
                case 'Library':
                    navigate('/');
                    break;
            }
        };

        return (
            <Flex
                key={item.label}
                component="a"
                href={item.link}
                align="center"
                onClick={(event) => {
                    event.preventDefault();
                    setActive(item.label);
                    handleClick();
                }}
                style={{
                    textDecoration: 'none',
                    color: isActive ? 'var(--mantine-color-teal-light-color)' : 'var(--mantine-color-gray-7)',
                    padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-sm)',
                    borderRadius: 'var(--mantine-radius-sm)',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.3s, color 0.3s',
                }}
                onMouseOver={(e) => {
                    if (!isActive) {
                        e.currentTarget.style.backgroundColor = 'var(--mantine-color-gray-0)';
                        e.currentTarget.style.color = 'var(--mantine-color-black)';
                    }
                }}
                onMouseOut={(e) => {
                    if (!isActive) {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.color = 'var(--mantine-color-gray-7)';
                    }
                }}
            >
                <Box pos="relative">
                    <item.icon
                        style={{
                            color: isActive ? 'var(--mantine-color-teal-light-color)' : 'var(--mantine-color-gray-6)',
                            marginRight: 'var(--mantine-spacing-sm)',
                            width: '25px',
                            height: '25px',
                            stroke: '1.5',
                        }}
                    />
                    {item.label === 'Cart' && cart.length > 0 && (
                        <Badge
                            size="xs"
                            variant="filled"
                            color="red"
                            style={{
                                position: 'absolute',
                                top: -10,
                                right: -2,
                                borderRadius: '50%',
                                padding: '0.25rem',
                                minWidth: 'unset',
                            }}
                        >
                            {cart.length}
                        </Badge>
                    )}
                </Box>
                <Text fw={'bold'} fs={'md'}>{item.label}</Text>
            </Flex>
        );
    });

    const isTranscriptionRoute = useMemo(() => {
        const segments = location.pathname.split('/');
        return segments[1] === 'transcription';
    }, [location.pathname]);

    const isLibraryRoute = useMemo(() => {
        const segments = location.pathname.split('/');
        return segments[1] === 'library';
    }, [location.pathname]);



    useEffect(() => {
        if (isTranscriptionRoute) {
            setSession(libraryNavigation.sessions[sessionId])
        }
    }, []);

    useEffect(() => {
        if (isTranscriptionRoute) {
            setAsideWidth(700)
        }
    }, [isTranscriptionRoute]);

    useEffect(() => {
        if (isLibraryRoute && libraryNavigation.displayLibrarySidebar) {
            setAsideWidth(700);
        } else if (isLibraryRoute && !libraryNavigation.displayLibrarySidebar) {
            setAsideWidth(350);
        }
    }, [libraryNavigation.displayLibrarySidebar, isLibraryRoute]);

    useEffect(() => {
        if (!isTranscriptionRoute && !isLibraryRoute) {
            setAsideWidth(0)
        }
    }, [location]);

    return (
        <AppShell
            navbar={{width: 200, breakpoint: 'sm', collapsed: { mobile: !mobileOpened, desktop: !desktopOpened }}}
            padding={{ sm: 'md', md: 'lg' }}
            aside={{ width: asideWidth, breakpoint: 'md', collapsed: { desktop: false, mobile: true } }}
            styles={{
                root: {
                    height: '100vh',
                    overflow: 'hidden',
                },
                main: {
                    height: '100%', // Subtract header height
                },
            }}
        >
            <AppShell.Navbar p="md">
                {links}
            </AppShell.Navbar>
            <AppShell.Main bg={'gray.1'}>
                {children}
            </AppShell.Main>
            {(isTranscriptionRoute && session) &&
                <AppShell.Aside bg={'white'}>
                    <TranscriptionTaskSidebar />
                </AppShell.Aside>
            }
            {(isLibraryRoute && libraryNavigation.displayLibrarySidebar) &&
                <AppShell.Aside bg={'white'}>
                    <PodcastLibraryEpisodeSidebar/>
                </AppShell.Aside>
            }
            {(isLibraryRoute && !libraryNavigation.displayLibrarySidebar) &&
                <AppShell.Aside bg={'white'}>
                    <PodcastLibrarySidebar/>
                </AppShell.Aside>
            }
        </AppShell>
    );
}
