import React, {useCallback, useEffect, useState} from 'react';
import { Podcast } from "../../models/Podcast";
import {Button, Flex, Group, Image, Paper, Pill, SegmentedControl, Text} from '@mantine/core';
import {EpisodesIcon} from "../../assets/EpisodesIcon";
import {removePTags} from "../../utils/text-utils";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";
import PodcastOverviewModule from "./podcast-overview-module";
import {useUser} from "../../context/UserContext";
import {useFetchRss} from "../../hooks/usePodcastLibraryQueries";
import {useToast} from "@chakra-ui/react";

export default function PodcastLibrarySidebar() {
    const {currentPodcast} = usePodcastLibrary()
    const { mutate: fetchRss, isSuccess, isError  } = useFetchRss();

    const user = useUser();
    const toast = useToast();

    const [activeTab, setActiveTab] = useState('overview');

    return (
        <Flex w={'100%'} direction="column" align="flex-start" justify={'space-between'} style={{ alignSelf: 'stretch' , overflow: 'auto'}}>
            <Paper p={'md'} w={'100%'} radius="md" >
                <Flex align={'flex-start'} gap={'md'} direction={'column'}>
                    <Image src={currentPodcast?.image_url} radius="md" />
                    <Flex direction="column" gap="xs" style={{ flex: '1 0 0' }}>
                        <Text c="gray.8" fz="md" fw={600}>
                            {currentPodcast?.title}
                        </Text>
                        <Group gap="xs">
                            <EpisodesIcon />
                            <Text c="gray.7" fz="sm">
                                {currentPodcast?.episodes.length} Episodes
                            </Text>
                        </Group>
                    </Flex>
                </Flex>
            </Paper>


            <Flex w={'100%'} direction="column" justify={'flex-start'} align="flex-start" gap="md" style={{ alignSelf: 'stretch' }}>
                <SegmentedControl
                    w={'100%'}
                    value={activeTab}
                    color={'teal'}
                    size={'md'}
                    onChange={setActiveTab}
                    data={[
                        { label: 'Overview', value: 'overview' },
                    ]}
                    styles={{
                        root: {
                            backgroundColor: 'white',
                            borderBottom: '1px solid #E2E8F0',
                            borderTop: '1px solid #E2E8F0',
                            padding: '10px 10px',
                        },

                    }}
                />
            </Flex>
            <Flex w={'100%'} p={'md'} direction="column" align="flex-start" style={{ alignSelf: 'stretch' , overflow: 'auto'}}>
                {activeTab === 'overview' && <PodcastOverviewModule podcast={currentPodcast as Podcast}/>}
            </Flex>
        </Flex>
    );
}
