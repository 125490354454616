import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useUser } from './UserContext';
import { usePodcasts, useEpisodes, useEpisodesInReview, useFetchRss } from '../hooks/usePodcastLibraryQueries';
import { Podcast } from '../models/Podcast';
import {useCart} from "../hooks/useCartQueries";
import {PodcastCartItem} from "../models/Cart";

interface PodcastLibraryContextType {
    currentPodcast: Podcast | undefined
    currentPodcastId: string | null;
    currentPodcastEpisodes: any[];
    setCurrentPodcastEpisodes: (episodes: any[]) => void;
    setCurrentPodcastId: (id: string | null) => void;
    setCurrentPodcast: any
    cart: PodcastCartItem[]
    isEpisodeInCart: (episodeId: string) => boolean;
    localizationTasks: any[];
    setLocalizationTasks: (tasks: any[]) => void;
}

const PodcastLibraryContext = createContext<PodcastLibraryContextType | null>(null);

export const PodcastLibraryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const user: any = useUser();
    const [currentPodcastId, setCurrentPodcastId] = useState<string | null>(null);
    const [currentPodcast, setCurrentPodcast] = useState<Podcast>()
    const [localizationTasks, setLocalizationTasks] = useState<any[]>([]);
    const [currentPodcastEpisodes, setCurrentPodcastEpisodes] = useState<any[]>([]);
    const { data: cart = [] } = useCart();

    const isEpisodeInCart = (episodeId: string) => cart.some(item => item.episode.id === episodeId);

    const value = {
        cart,
        currentPodcast,
        currentPodcastEpisodes,
        setCurrentPodcastEpisodes,
        localizationTasks,
        isEpisodeInCart,
        currentPodcastId,
        setCurrentPodcastId,
        setCurrentPodcast,
        setLocalizationTasks,
    };

    return (
        <PodcastLibraryContext.Provider value={value}>
            {children}
        </PodcastLibraryContext.Provider>
    );
};

export const usePodcastLibrary = () => {
    const context = useContext(PodcastLibraryContext);
    if (!context) {
        throw new Error('usePodcastLibrary must be used within a PodcastLibraryProvider');
    }
    return context;
};

export const usePodcastLibraryData = () => {
    const { currentPodcastId } = usePodcastLibrary();
    const user: any = useUser();
    const podcastsQuery = usePodcasts(user);
    const episodesQuery = useEpisodes(currentPodcastId || '');
    const episodesInReviewQuery = useEpisodesInReview(user);
    const fetchRssMutation = useFetchRss();

    return {
        podcasts: podcastsQuery,
        episodes: episodesQuery,
        episodesInReview: episodesInReviewQuery,
        fetchRss: fetchRssMutation,
    };
};
