import {useAuth} from "../../context/AuthContext";
import React from "react";
import 'react-circular-progressbar/dist/styles.css';
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {ConfigurationModule} from "./configuration-module";
import {Flex} from "@mantine/core";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {Icon} from "@chakra-ui/react";
import {ArrowLeftIcon, ChevronLeftIcon} from "@chakra-ui/icons";

export default function PodcastLibraryEpisodeSidebar() {
    const auth: any = useAuth()
    const libraryNavigation: any = useLibraryNavigation()

    return (
        <Flex direction={'column'} gap={'md'} w={'full'}>
            <Flex p={'md'} direction={'row'} gap={'md'} w={'full'}>
                <ChevronLeftIcon onClick={() => {
                    libraryNavigation.setDisplaySidebar(false)
                }} />
            </Flex>
            <ConfigurationModule episode={libraryNavigation.currentEpisode}/>
        </Flex>
    )
}
