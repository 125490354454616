import {Flex, Tabs} from "@mantine/core";
import {useLibraryNavigation} from "../context/LibraryNavigationContext";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../models/Localization";
import {IconCircleCheck, IconCircleDashedCheck, IconProgressCheck} from "@tabler/icons-react";
import TranscriptReader from "../pages/transcript-reader";
import {TranscriptMode} from "../models/Transcript";
import TranscriptionBreadcrumbs from "./transcription-breadcrumbs";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {Image, Skeleton, Text} from "@chakra-ui/react";
import {secondsToHHMMSS} from "../utils/firebase";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import ReactPlayer from "react-player";


enum TaskState {
    NOT_STARTED = 'Not Started',
    IN_PROGRESS = 'In Progress',
    COMPLETED = 'Completed',
}
export default function TranscriptionStudio() {
    const libraryNavigation: any = useLibraryNavigation()
    const {currentPodcast} = usePodcastLibrary()
    const { sessionId }: any = useParams();
    const [session, setSession] = useState<TranscriptionSession>(libraryNavigation.studioSession)
    const [taskStates, setTaskStates] = useState<any>({
        AI: TaskState.NOT_STARTED,
        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
        EDITOR: TaskState.NOT_STARTED,
        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
        FINAL: TaskState.NOT_STARTED
    })

    useEffect(() => {
        if (session) {
            console.log('session', session)
            switch (session.state) {
                case TranscriptionState.SPEECH_TO_TEXT: {
                    setTaskStates({
                        AI: TaskState.IN_PROGRESS,
                        TRANSCRIPTIONIST: TaskState.NOT_STARTED,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PROOFREADING_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.READY_FOR_PROOFREADING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.IN_PROGRESS,
                        EDITOR: TaskState.NOT_STARTED,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.EDITOR_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.EDITOR_REVIEW: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.IN_PROGRESS,
                        PUBLISHER_REVIEW: TaskState.NOT_STARTED,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }

                case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.PUBLISHER_REVIEWING: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.IN_PROGRESS,
                        FINAL: TaskState.NOT_STARTED
                    })
                    break
                }
                case TranscriptionState.COMPLETED: {
                    setTaskStates({
                        AI: TaskState.COMPLETED,
                        TRANSCRIPTIONIST: TaskState.COMPLETED,
                        EDITOR: TaskState.COMPLETED,
                        PUBLISHER_REVIEW: TaskState.COMPLETED,
                        FINAL: TaskState.IN_PROGRESS
                    })
                    break
                }
            }
        }
    }, []);

    const StateIcon = ({state}: {state: TaskState}) => {
       return (
           <>
               {state === TaskState.NOT_STARTED && <IconCircleDashedCheck stroke={2} style={{color: 'gray'}}/>}
                {state === TaskState.IN_PROGRESS && <IconProgressCheck stroke={2} style={{color: 'orange'}}/>}
                {state === TaskState.COMPLETED && <IconCircleCheck stroke={2} style={{color: 'teal'}}/>}
           </>
       )
    }

    return (
        <Flex w={'100%'} direction={'column'} gap={'md'}>
            <Flex w={'100%'} p="16px" bg={'white'} h={'full'} direction="row" align="center" justify="flex-start" py={'3px'} style={{borderRadius: '12px', border: '1px solid #e2e8f0'}}>
                <ChevronLeftIcon  boxSize={'36px'} onClick={() => {
                    window.history.back()
                }}/>
                <Image src={(session as PodcastTranscriptionTaskMeta)?.episode?.image_url? (session as PodcastTranscriptionTaskMeta)?.episode?.image_url : currentPodcast?.image_url} boxSize={'64px'} />
                <Flex direction={'column'} align={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                    <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                        {(session as PodcastTranscriptionTaskMeta)?.episode?.title}
                    </Text>
                    <Flex direction={'row'} align={'center'} gap={'8px'}>
                        <Text color="gray.500" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            {secondsToHHMMSS((session as PodcastTranscriptionTaskMeta)?.episode?.duration as any)}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w={'100%'} p="16px" h={'full'} direction="row" align="center" justify="center" py={'3px'}>
                <Flex direction="column" w={'100%'} h={'100%'} p={'20px'} bg={'white'} style={{borderRadius: '12px', border: '1px solid #e2e8f0'}}>
                    {!session && <Skeleton w={'full'} h={'full'} />}
                    {!!session && <>
                        <ReactPlayer
                            width={'100%'}
                            height={'500px'}
                            controls={true}
                            url= {session?.youtube_url}
                            style={{borderRadius: '12px'}}
                        />
                    </>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}
