import {
    Button,
    Flex,
    Image,
    Menu, MenuButton, MenuItem, MenuList,
    Spinner,
    Tag,
    TagLabel,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useUser} from "../../context/UserContext";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {useLocalization} from "../../context/LocalizationContext";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {useNavigate, useParams} from "react-router-dom";
import {apiStates, ENDPOINTS, useApi} from "../../api/braincap-api";
import {EditorMeta} from "../../models/Editor";
import {UserCategory} from "../../models/Users";
import {AiOutlineFileSync} from "react-icons/ai";

export const EpisodeReviewCard = ({episode, userType}: {episode: any, userType: UserCategory}) => {
    const user: any = useUser()
    const localization: any = useLocalization()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isInCart, setIsInCart] = useState<boolean>(false)
    const [waitingServerConfirmation, setWaitingServerConfirmation] = useState<boolean>(false)
    const [startReview, setStartReview] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const { podcastId } = useParams()
    let image_url = 'https://megaphone.imgix.net/podcasts/042e6144-725e-11ec-a75d-c38f702aecad/image/Huberman-Lab-Podcast-Thumbnail-3000x3000.png?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress'

    return (
        <Flex p="12px" w={'fit-content'} flexDirection="column" alignItems="center" justifyContent={'center'} bgColor={'#fff'} borderRadius="12px" boxShadow={'sm'} wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis'>
               <Flex w={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
                   <Flex position={'relative'} alignItems="center" justifyContent={'center'}>
                       <Image fallbackSrc='https://via.placeholder.com/150'  boxSize={'full'} src={image_url} borderRadius="8px" bgColor="gray.100"/>
                   </Flex>
                   <Tooltip label={`${episode.episode.title}`}>
                       <Text  w={'full'} noOfLines={1} color="gray.700" fontSize="16px" fontWeight="500" lineHeight="24px">{episode.episode.title}</Text>
                   </Tooltip>
                   <Flex w={'full'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                       <Text  color='gray.500' fontSize={'14px'} fontFamily={'sans-serif'} fontStyle={'normal'} fontWeight={400}>
                           {secondsToHHMMSS(episode.episode.duration)}
                       </Text>
                   </Flex>
                   <Flex as={Button} w={'full'} p={'6px'} direction={'row'} alignItems={'center'} justifyContent={'center'} variant={'outline'}>
                       {loading && <Spinner alignSelf={'center'} colorScheme={'teal'}/>}
                       {!loading && userType === UserCategory.PUBLISHER &&
                           <Menu>
                               <MenuButton userSelect={'none'} w={'full'}>
                                   <Text contentEditable={false} color="teal.600" fontSize="16px" fontWeight="600" lineHeight="20px">
                                       Open
                                   </Text>
                               </MenuButton>
                               <MenuList>
                                   <MenuItem contentEditable={false} onClick={() => {
                                       navigate(`/review/${episode.id}/${episode.podcast.id}/${episode.episode.id}`)
                                   }}>
                                        <Text color="teal.600" fontSize="14px" fontWeight="500" lineHeight="20px">
                                            Editor Transcript
                                        </Text>
                                   </MenuItem>
                                   <MenuItem contentEditable={false} onClick={() => {
                                       navigate(`/reader/${episode.id}/proofread`)
                                   }}>
                                       <Text color="teal.600" fontSize="14px" fontWeight="500" lineHeight="20px">
                                           Transcriptionist Transcript
                                       </Text>
                                   </MenuItem>
                                   <MenuItem contentEditable={false} onClick={() => {
                                       navigate(`/reader/${episode.id}/asr`)
                                   }}>
                                       <Text color="teal.600" fontSize="14px" fontWeight="500" lineHeight="20px">
                                           AI Transcript
                                       </Text>
                                   </MenuItem>
                               </MenuList>
                           </Menu>
                       }
                   </Flex>
               </Flex>
        </Flex>
    )
}

export const EpisodeReadyForReviewItems = ({episode}: {episode: PodcastEpisode}) => {
    const user: any = useUser()
    const [loading, setLoading] = useState<boolean>(false)
    const [sentToEditor, setSentToEditor] = useState<boolean>(false)
    const dbPath = `${episode.transcription.session?.id}`
    const createReviewSessionReq = useApi(ENDPOINTS.PUBLISHER_CREATE_REVIEW_SESSION.concat(dbPath), user.token, false)
    const toast = useToast()

    useEffect(() => {
        if (!createReviewSessionReq.loading && createReviewSessionReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Success',
                description: 'Successfully sent to editor',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setSentToEditor(true)
        }
    }, [createReviewSessionReq.loading])

    return (
        <Flex hidden={sentToEditor} gap={'8px'} borderRadius={'12px'} p={'12px'} bgColor={'white'} justifyContent={'space-between'} direction={'row'} boxShadow={'md'} height="auto">
            <Flex direction={'row'} gap={'16px'} alignItems={'center'}>
                <Tooltip label={episode.title} placement={'top'}>
                    <Text color={'gray.900'} fontSize={'16px'} fontFamily={'sans-serif'} fontStyle={'normal'} fontWeight={500} overflow='hidden' textOverflow='ellipsis' noOfLines={1}>
                        {episode.title}
                    </Text>
                </Tooltip>
            </Flex>
            <Flex>
                {loading && <Spinner alignSelf={'center'} colorScheme={'teal'}/>}
                {!sentToEditor && !loading && <Button onClick={() => {
                    setLoading(true)
                    createReviewSessionReq.setPayloadData({
                        episode: episode,
                        publisher: user.user
                    })
                }} variant={'outline'} borderRadius={'6px'} border={'1px solid'}>
                    Send to Editor
                </Button>
                }
                {sentToEditor && !loading && <Flex>
                    <Tag size={'lg'} key={'lg'} borderRadius='full' variant='solid' bgColor={'gray.100'}>
                        <TagLabel fontWeight={600} fontSize={14} color={'gray.700'}>Sent to Editor</TagLabel>
                    </Tag>
                </Flex>
                }
            </Flex>
        </Flex>
    )
}
export const EpisodeOngoingReviewItems = ({session}: {session: TranscriptionSession}) => {
    const user: any = useUser()

    return (
        <Flex gap={'8px'} borderRadius={'12px'} p={'12px'} bgColor={'white'} justifyContent={'space-between'} direction={'row'} boxShadow={'md'} height="auto">
            <Flex direction={'row'} gap={'16px'} alignItems={'center'}>
                <Tooltip label={(session as PodcastTranscriptionTaskMeta).episode.title} placement={'top'}>
                    <Text color={'gray.900'} fontSize={'16px'} fontFamily={'sans-serif'} fontStyle={'normal'} fontWeight={500} overflow='hidden' textOverflow='ellipsis' noOfLines={1}>
                        {(session as PodcastTranscriptionTaskMeta).episode.title}
                    </Text>
                </Tooltip>
            </Flex>
            <Flex>
                {session.state === TranscriptionState.EDITOR_REVIEW &&
                    <Flex>
                        <Tag size={'lg'} key={'lg'} borderRadius='full' variant='solid' bgColor={'yellow.100'}>
                            <TagLabel fontWeight={600} fontSize={14} color={'gray.700'}>Not started</TagLabel>
                        </Tag>
                    </Flex>
                }
                {session.state === TranscriptionState.EDITOR_REVIEWING &&
                    <Flex>
                        <Tag size={'lg'} key={'lg'} borderRadius='full' variant='solid' bgColor={'teal.100'}>
                            <TagLabel fontWeight={600} fontSize={14} color={'gray.700'}>Editor reviewing</TagLabel>
                        </Tag>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}

