import axios from 'axios';

const apiClient = axios

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('AUTH_TOKEN');
        if (token) {
            config = {
                ...config,
                headers: {
                    Authorization: `Bearer${token}`,
                },
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const PODCASTLIBRARYENDPOINTS = {
    PODCASTS: '/publisher/podcasts',
    EPISODES: '/publisher/episodes',
    EPISODES_IN_REVIEW: '/publisher/review/get/DeJywyienIQBwckWsjOm3JdMH3t1',
    EPISODES_REVIEW_ONGOING: '/publisher/review/ongoing/get/DeJywyienIQBwckWsjOm3JdMH3t1',
    MEDIA_IN_PRODUCTION: '/publisher/mediaContent',
    CART: '/cart/get',
    FETCH_RSS: '/publisher/fetchRss',
    APP_CONFIG: '/config',
    ADD_TO_CART: '/cart/add',
    REMOVE_FROM_CART: '/cart/delete',
};

export const STUDIOENDPOINTS = {
    GET_TRANSCRIPTION_SESSIONS: '/publisher/transcription/sessions',
}

export default apiClient;
