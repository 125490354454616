import {Podcast} from "../../models/Podcast";
import {Box, Button, Divider, Flex, Group, Image, Paper, Pill, Progress, Text} from "@mantine/core";
import {EpisodesIcon} from "../../assets/EpisodesIcon";
import {removePTags} from "../../utils/text-utils";
import React, {useCallback, useEffect, useState} from "react";
import { useDisclosure } from "@mantine/hooks";
import {useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {Toast, useToast} from "@chakra-ui/react";
import {YoutubeChannelInfo} from "../../models/Youtube";
import {ChannelDisplayModal, YoutubeMappingModal} from "../../components/youtube/youtube-mapping-modal";
import {YoutubePlaylistMappingModal} from "../../components/youtube/youtube-playlist-mapping-modal";
import {YoutubeEpisodeDisplayModal} from "../../components/youtube/youtube-episodes-mapping-modal";
import {useEpisodes, useFetchRss} from "../../hooks/usePodcastLibraryQueries";

export default function PodcastOverviewModule({podcast}: {podcast: Podcast}) {
    const user: any = useUser()
    const [opened, { open, close }] = useDisclosure(false);
    const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
    const youtubeLoginReq = useApi(`/youtube/login`, user.token, false)
    const youtubeChannelInfoReq = useApi(`/youtube/channel/info/${podcast.id}`, user.token, false)
    const [channelInfo, setChannelInfo] = useState<YoutubeChannelInfo>()
    const [youtubePlaylists, setYoutubePlaylists] = useState<any>([])
    const toast: any = useToast()
    const { mutate: fetchRss, isSuccess, isError  } = useFetchRss();

    const {data: episodes, isLoading: episodesLoading} = useEpisodes(podcast.id as string)

    const fetchRSSFeed = useCallback(() => {
        if (podcast) {
            fetchRss({ rss_feed: podcast.rss_feed, publisher: user, podcastId: podcast.id });
        }
    }, [podcast]);

    useEffect(() => {
        if (isSuccess) {
            toast({
                title: 'RSS Feed Updated',
                description: 'The RSS feed has been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        }

        if (isError) {
            toast({
                title: 'RSS Feed Update Failed',
                description: 'There was an error updating the RSS feed.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (!youtubeLoginReq.loading && youtubeLoginReq.data) {
            Toast({
                title: 'YouTube Login',
                description: 'Redirecting to YouTube for login',
                status: 'success',
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => {
                    console.log('Toast Closed')
                }
            })
            let url = youtubeLoginReq.data.url

            if (url) {
                window.location.href = url
            }
        } else if (!youtubeLoginReq.loading ) {
            Toast({
                title: 'YouTube Login Error',
                description: youtubeLoginReq.error,
                status: 'error',
                duration: 5000,
                isClosable: true,
                onCloseComplete: () => {
                    console.log('Toast Closed')
                }
            })
        }
    }, [youtubeLoginReq.loading]);

    useEffect(() => {
        if (!youtubeChannelInfoReq.loading && youtubeChannelInfoReq.data) {
            setChannelInfo(youtubeChannelInfoReq.data.channelInfo)
            setYoutubePlaylists(youtubeChannelInfoReq.data.playlists)
            open()
        } else if (!youtubeChannelInfoReq.loading) {
            toast({
                title: 'YouTube Channel Info',
                description: 'Error fetching YouTube Channel Info',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }, [youtubeChannelInfoReq.loading]);


    const statsData = [
        { label: "Speakers", value: 81 },
        { label: "Youtube", value: 81 },
        { label: "Youtube", value: 81 },
    ];

    const StatBox = ({ label, value }: { label: string; value: number }) => (
        <Paper p="md" radius="md" withBorder style={{ flex: 1 }}>
            <Text size="sm" fw={500} mb="xs">{label}</Text>
            <Text size="xs" color="dimmed" mt="xs">{value}%</Text>
        </Paper>
    );

    return (
        <Flex direction="column" align="flex-start" gap="md" style={{ alignSelf: 'stretch' }}>

            {podcast.youtube_connected === undefined && (
                <Button fullWidth variant={'outline'} onClick={() => {
                    fetchRSSFeed()
                }} color={'teal'}>
                    Fetch RSS Feed
                </Button>
            )}
            {podcast.youtube_connected !== undefined && podcast.youtube_connected && !podcast.youtube_meta.is_mapped && (
                <Pill color="red" size="sm" radius="xl" onClick={() => {
                    youtubeChannelInfoReq.execute()
                    toast({
                        title: 'YouTube Mapping',
                        description: 'Fetching YouTube playlists Info',
                        status: 'info',
                        duration: 9000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }}>
                    Map Youtube Playlist
                </Pill>
            )}
            {podcast.youtube_connected !== undefined && podcast.youtube_connected && podcast.youtube_meta.is_mapped && (
                <>
                    <Pill color="red" size="sm" radius="xl" onClick={() => {
                        toast({
                            title: 'YouTube Mapping',
                            description: 'Fetching YouTube playlists Info',
                            status: 'info',
                            duration: 9000,
                            isClosable: true,
                            position: 'top-right'
                        })
                    }}>
                        Playlist Mapped
                    </Pill>
                    {!episodesLoading && episodes && episodes.length > 0 && (
                        <Pill color="red" size="sm" radius="xl" onClick={() => {
                            open2()
                            toast({
                                title: 'YouTube Episodes Mapping',
                                description: 'Fetching Playlist videos',
                                status: 'info',
                                duration: 9000,
                                isClosable: true,
                                position: 'top-right'
                            })
                        }}>
                            Map Episodes
                        </Pill>
                    )}
                </>
            )}

            {channelInfo && podcast.youtube_connected !== undefined && podcast.youtube_connected && (
                <YoutubePlaylistMappingModal opened={opened} close={close} podcast={podcast} youtubePlaylists={youtubePlaylists} />
            )}
            {episodes && podcast.youtube_connected !== undefined && podcast.youtube_connected && podcast.youtube_meta.is_mapped && (
                <YoutubeEpisodeDisplayModal opened={opened2} close={close2} podcast={podcast} episodes={episodes} />
            )}
        </Flex>
    );
}
