import {useAuth} from "./context/AuthContext";
import {Route, Routes} from "react-router-dom";
import Signup from "./pages/auth/Signup";
import PinVerification from "./pages/auth/PinVerification";
import PublisherLayout from "./pages/publisher-layout";
import Login from "./pages/auth/Login";
import Podcasts from "./pages/library/Podcasts";
import TranscriptReader from "./pages/transcript-reader";
import ReviewerLayout from "./pages/reviewer-layout";
import ReviewerDashboard from "./pages/reviewer/Reviewer-Dashboard";
import ReviewerTranscriptPage from "./components/reviewer/reviewer-transcript-page";
import {TranscriptMode} from "./models/Transcript";
import PodcastLibrary from "./pages/library/Podcast-Library";
import RedirectUriLoader from "./components/youtube/redirect-uri-loader";
import TranscriptionStudio from "./studio/transcription-studio";
import CartPanel from "./components/cart/cart-panel";

export const PAGE = {
    HOME: 'Podcasts',
    PODCAST: 'Podcast',
    LIBRARY: 'Library',
    EPISODES: 'Episodes',
    CONFIGURATION: "Configuration",
    TASKS: "TASKS",
    TRANSCRIPTION: "Transcription",
    TRANSLATION: "Translation",
    MAKTUB: "Maktub",
    ACCOUNT: "Account",
};

export default function App() {
    const auth: any = useAuth()
    const isLoggedIn = !!auth.token
    console.log(`Are we loggedIn: ${!!auth.token}`)

    return (
        <Routes>
            {/* Entry Routes*/}
            <Route path="/signup/:category/:is_invite/:google/:podcastId?/:invitationId?" element={<Signup/>} />
            <Route path="/pin" element={isLoggedIn ?
                <PinVerification/>
                :
                <Login/>
            }
            />
            <Route path="/" element={isLoggedIn ? <PublisherLayout name={PAGE.LIBRARY} children={<Podcasts/>}/> : <Login/>} />
            <Route path="/oauth2callback" element={isLoggedIn ? <RedirectUriLoader /> : <Login/>} />
            <Route path="/library" element={isLoggedIn ? <PublisherLayout name={PAGE.LIBRARY} children={<Podcasts/>}/> : <Login/>} />
            <Route path="/library/:podcastId" element={isLoggedIn ? <PublisherLayout name={PAGE.EPISODES} children={<PodcastLibrary/>}/> : <Login/>} />
            <Route path="/cart" element={isLoggedIn ? <PublisherLayout name={PAGE.EPISODES} children={<CartPanel/>}/> : <Login/>} />


            <Route path="/transcription/:sessionId" element={isLoggedIn ? <PublisherLayout name={PAGE.MAKTUB} children={<TranscriptionStudio />}/> : <Login/>} />
            <Route path="/review/:sessionId/:podcastId/:episodeId" element={isLoggedIn ? <PublisherLayout name={PAGE.MAKTUB} children={<TranscriptReader mode={TranscriptMode.REVIEWING}/>}/> : <Login/>} />

            <Route path="/r/dashboard" element={isLoggedIn ? <ReviewerLayout name={PAGE.LIBRARY} children={<ReviewerDashboard/>}/> : <Login/>} />
            <Route path="/r/review/:sessionId/:podcastId/:episodeId" element={isLoggedIn ? <ReviewerLayout name={PAGE.MAKTUB} children={<ReviewerTranscriptPage/>}/> : <Login/>} />
        </Routes>
    );
}
