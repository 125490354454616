import React, {useEffect, useMemo} from 'react';
import {Text, Card, RingProgress, Group, useMantineTheme, Box, Flex, Paper, SimpleGrid} from '@mantine/core';
import {IconEye, IconUsers, IconVideo} from "@tabler/icons-react";
import {usePodcastLibrary} from "../context/PodcastLibraryContext";
import {Image} from "@chakra-ui/react";
import {EpisodesIcon} from "../assets/EpisodesIcon";
import {YoutubeChannelInfoModule} from "./youtube/youtube-channel-module";
import {LocalizationProgressFilter} from "../pages/library/Podcast-Dashboard";
import {useEpisodes} from "../hooks/usePodcastLibraryQueries";
import {useParams} from "react-router-dom";
import {TranscriptionState} from "../models/Localization";

export const PodcastLocalizationProgress = ({step, setFilter}: {step: string, setFilter: any }) => {
    const {podcastId} = useParams()
    const { data: podcastEpisodesData, isLoading: episodesLoading, error: episodeError } = useEpisodes(podcastId as string);
    const [totalCompleted, setTotalCompleted] = React.useState(0);
    const [totalInProgress, setTotalInProgress] = React.useState(0);
    const [totalNotStarted, setTotalNotStarted] = React.useState(0);

    const episodes = useMemo(() => podcastEpisodesData || [], [podcastEpisodesData]);

    useEffect(() => {
        if (!episodesLoading && episodes.length > 0) {
            switch (step) {
                case 'Transcription':
                    setTotalCompleted(episodes.filter(episode => episode.transcription.state === TranscriptionState.COMPLETED).length);
                    setTotalNotStarted(episodes.filter(episode => episode.transcription.state === TranscriptionState.NOT_CREATED).length);
                    setTotalInProgress(episodes.filter(episode => episode.transcription.state !== TranscriptionState.COMPLETED && episode.transcription.state !== TranscriptionState.NOT_CREATED).length);

                    break;
                case 'Translation':
                    setTotalCompleted(episodes.filter(episode => episode.translation.completed.length > 0).length);
                    setTotalInProgress(episodes.filter(episode => episode.translation.processing.length > 0).length);
                    setTotalNotStarted(episodes.filter(episode => episode.translation.completed.length === 0 && episode.translation.processing.length === 0).length);
                    break;
                case 'Dubbing':
                    setTotalCompleted(episodes.filter(episode => episode.dubbing.completed.length > 0).length);
                    setTotalInProgress(episodes.filter(episode => episode.dubbing.processing.length > 0).length);
                    setTotalNotStarted(episodes.filter(episode => episode.dubbing.completed.length === 0 && episode.dubbing.processing.length === 0).length);
            }
        }
    }, [podcastEpisodesData]);

    const handleFilterCompleted = () => {
        switch (step) {
            case 'Transcription':
                setFilter(LocalizationProgressFilter.TRANSCRIPTION_COMPLETED)
                break;
            case 'Translation':
                setFilter(LocalizationProgressFilter.TRANSLATION_COMPLETED)
                break;
            case 'Dubbing':
                setFilter(LocalizationProgressFilter.DUBBING_COMPLETED)
                break;
            default:
                setFilter('all')
                break;
        }

    }


    const handleFilterNotStarted = () => {
        switch (step) {
            case 'Transcription':
                setFilter(LocalizationProgressFilter.TRANSCRIPTION_NOT_STARTED)
                break;
            case 'Translation':
                setFilter(LocalizationProgressFilter.TRANSLATION_NOT_STARTED)
                break;
            case 'Dubbing':
                setFilter(LocalizationProgressFilter.DUBBING_NOT_STARTED)
                break;
            default:
                setFilter('all')
                break;
        }
    }

    const handleFilterInProgress = () => {
        switch (step) {
            case 'Transcription':
                setFilter(LocalizationProgressFilter.TRANSCRIPTION_IN_PROGRESS)
                break;
            case 'Translation':
                setFilter(LocalizationProgressFilter.TRANSLATION_IN_PROGRESS)
                break;
            case 'Dubbing':
                setFilter(LocalizationProgressFilter.DUBBING_IN_PROGRESS)
                break;
            default:
                setFilter('all')
                break;
        }
    }


    const stats = [
        { value: totalNotStarted, label: 'Remaining', onclick: handleFilterNotStarted},
        { value: totalInProgress, label: 'In progress', onclick: handleFilterInProgress},
    ];

    const theme = useMantineTheme();
    const completed = totalCompleted;
    const total = episodes.length;

    const items = stats.map((stat) => (
        <Box
            key={stat.label}
            component={'button'}
            onClick={stat.onclick}
            style={(theme) => ({
                '&:hover': {
                    backgroundColor: theme.colors.gray[1],
                },
            })}
        >
            <Text fw={700} lh={1}>{stat.value}</Text>
            <Text size="xs" c="dimmed">
                {stat.label}
            </Text>
        </Box>
    ));

    return (
        <Card w={'100%'} withBorder p="md" radius="md" bg="var(--mantine-color-body)">
            <Flex
                direction={{ base: 'column', sm: 'row' }}
                align={{ base: 'center', sm: 'flex-start' }}
                justify="space-between"
                w={'100%'}
            >
                <Box>
                    <Text fz="xl" fw={700} lh={1}>
                        {step}
                    </Text>
                    <Box mt={30} component={'button'} onClick={handleFilterCompleted}>
                        <Text fw={700} fz={22} lh={1}>
                            {totalCompleted}
                        </Text>
                        <Text fz="xs" c="dimmed">
                            Completed
                        </Text>
                    </Box>
                    <Group mt="lg">{items}</Group>
                </Box>
                <Box mt={{ base: 'md', sm: 0 }}>
                    <RingProgress
                        roundCaps
                        thickness={6}
                        size={150}
                        sections={[{ value: (completed / total) * 100, color: theme.primaryColor }]}
                        label={
                            <Box ta="center">
                                <Text fz="lg" fw={700} lh={1}>
                                    {((completed / total) * 100).toFixed(0)}%
                                </Text>
                                <Text fz="xs" c="dimmed">
                                    Completed
                                </Text>
                            </Box>
                        }
                    />
                </Box>
            </Flex>
        </Card>
    )
}

export const RssLocalizationProgress = ({setProgressFilter}: { setProgressFilter: any }) => {
    const {currentPodcast: podcast} = usePodcastLibrary()

    const data = [
        { title: 'Total Views', icon: <IconEye stroke={2} />, value: 0},
    ] as const

    let stats = []
    stats.push(
        <Paper w={'100%'} withBorder p="md" radius="md" key={'rss-stats'}>
            <Group justify="space-between">
              <Flex direction={'row'} align={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                  <Image src={podcast?.image_url} boxSize={'100px'} />
                  <Flex direction={'column'} align={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                      <Text c="gray.8"  fs="16px"  fw="600" lh="24px">
                          {podcast?.title}
                      </Text>
                      <Flex direction={'row'} align={'center'} gap={'8px'}>
                          <EpisodesIcon/>
                          <Text c="gray.7" fs="14px"  fw="400" lh="20px">
                              {podcast?.episodes.length} Episodes
                          </Text>
                      </Flex>
                      {podcast?.youtube_connected && podcast.youtube_meta.channelInfo && (
                          <YoutubeChannelInfoModule channel={podcast.youtube_meta.channelInfo} />
                      )}
                  </Flex>
              </Flex>
            </Group>
        </Paper>
    )

     data.map((stat) => {
         return stats.push(
             <Paper w={'100%'} withBorder p="md" radius="md" key={stat.title}>
                 <Group justify="space-between">
                     <Flex direction={'column'} align={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                         <Text c="gray.8" fs="16px" fw="600" lh="24px">
                             {stat.title}
                         </Text>
                         <Text c="gray.7" fs="14px" fw="400" lh="20px">
                             {stat.value}
                         </Text>
                     </Flex>
                     {stat.icon}
                 </Group>
             </Paper>
         )
    });
    return (
        <Flex direction={'row'} w={'100%'} align={'center'} justify={'space-between'} gap={'24px'}>
            <PodcastLocalizationProgress step={'Transcription'} setFilter={setProgressFilter}/>
            <PodcastLocalizationProgress step={'Translation'} setFilter={setProgressFilter}/>
            <PodcastLocalizationProgress step={'Dubbing'} setFilter={setProgressFilter}/>
        </Flex>
    );
}
