import {
    CloseButton,
    Flex,
    FormControl,
    FormErrorMessage,
    Grid,
    Input,
    InputGroup,
    InputRightAddon,
    Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {SpeakerInvitation, SpeakerType} from "../../models/Speakers";
import {BraincapService} from "../../models/Localization";
import {useLocalization} from "../../context/LocalizationContext";

export const HostConfiguration = ({ episode, validate}: {
    episode: PodcastEpisode
    validate: boolean
}) => {
    const [singleEpisode, setSingleEpisode] = useState(episode as PodcastEpisode)
    const localization: any = useLocalization()

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    HOST
                </Text>
                {/*
                <Icon as={LuEdit2} boxSize={'24px'}/>
                */}
            </Flex>
            <SpeakerInput episode={singleEpisode} type={SpeakerType.HOST} validate={validate}/>
        </Flex>
    )
}

export const GuestConfiguration =  ({ episode, validate}: {
    episode: PodcastEpisode | PodcastEpisode[]
    validate: boolean
}) => {
    const [singleEpisode, setSingleEpisode] = useState(episode as PodcastEpisode)
    const localization: any = useLocalization()

    useEffect(() => {
        setSingleEpisode(episode as PodcastEpisode)
    }, [episode])
    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    GUEST
                </Text>
                {/*
                <Icon as={LuEdit2} boxSize={'24px'}/>
                */}
            </Flex>
            <SpeakerInput episode={singleEpisode} type={SpeakerType.GUEST} validate={validate}/>
        </Flex>
    )
}

export const SpeakerInput = ({episode, type, validate}: {episode: PodcastEpisode, validate: boolean, type: SpeakerType}) => {
    const localization: any = useLocalization()
    const [speakers, setSpeakers] = useState<SpeakerInvitation[]>([])
    const [inputValue, setInputValue] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [speakersList, setSpeakersList] = useState<SpeakerInvitation[]>(localization.episodesConfiguration[(episode as PodcastEpisode).id]?.speakers)
    const [service, setService] = useState<BraincapService>(localization.episodesConfiguration[(episode as PodcastEpisode).id]?.service)

    useEffect(() => {
        setSpeakersList(localization.episodesConfiguration[(episode as PodcastEpisode).id]?.speakers)
    }, [localization.episodesConfiguration[(episode as PodcastEpisode).id]?.speakers])

    useEffect(() => {
        setService(localization.episodesConfiguration[(episode as PodcastEpisode).id]?.service)
    }, [service])

    useEffect(() => {
        if (validate) {
            let configs: any = {...localization.episodesConfiguration}
            let currentSpeaker = configs[(episode as PodcastEpisode).id]?.speakers
            let size = currentSpeaker?.length
            console.log(`size: ${size}`)
            if (size === 0) {
                setIsInvalid(true);
            } else if (size && size > 0) {
                setIsInvalid(false);
            }
        } else {
            setIsInvalid(false);
        }
    }, [validate])


    useEffect(() => {
        if (speakersList && speakersList.length > 0 && validate) {
            setIsInvalid(false)
        } else if (speakersList && speakersList.length === 0 && validate) {
            setIsInvalid(true)
        }
    }, [speakersList])

    useEffect(() => {
        if (service) {
            setSpeakers([])
        }
    }, [service])

    useEffect(() => {
        let configs: any = {...localization.episodesConfiguration}
        let validations: any = {...localization.configurationValidation}
        let config: any = {...localization.episodesConfiguration[(episode as PodcastEpisode).id]};
        let validation: any = {...localization.configurationValidation[(episode as PodcastEpisode).id]};
        configs[(episode as PodcastEpisode).id] = {
            ...config,
            speakers: speakers
        }
        let key = type === SpeakerType.HOST? 'host' : 'guest'

        validations[(episode as PodcastEpisode).id] = {
            ...validation,
            [key]: speakers.length > 0
        }
        localization.setEpisodesConfiguration(configs)
        localization.setConfigurationValidation(validations)
        if (speakers.length > 0) {
            setIsInvalid(false)
        } else {
            if (validate) {
                setIsInvalid(true)
            }
        }
    }, [speakers])

    const handleChange = (event: any) => {
        setInputValue(event.target.value)
    }
    const addSpeaker = () => {
        if (inputValue.length > 0) {
            let newSpeakers = [...speakers];
            let speaker: SpeakerInvitation = {
                name: inputValue,
                invited_as: type,
            }
            newSpeakers.push(speaker);
            setSpeakers(newSpeakers);
            setInputValue('');
        }
    }

    const removeSpeaker = (index: number) => {
        let newSpeakers = [...speakers]; // Create a copy
        newSpeakers.splice(index, 1);
        console.log(`newSpeakers: ${JSON.stringify(newSpeakers)}`)
        setSpeakers(newSpeakers);
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addSpeaker();
        }
    }

    const handleNameChange = (event: any, index: number) => {
        let newSpeakers = [...speakers]
        newSpeakers[index].name = event.target.value;
        setSpeakers(newSpeakers);
    }

    const handleEmailChange = (event: any, index: number) => {
        let newSpeakers = [...speakers]
        newSpeakers[index].email = event.target.value;
        setSpeakers(newSpeakers);
    }

    const InputComponent = ({speaker, index}: { speaker: SpeakerInvitation, index: number}) => {
        return (
            <Flex w={'full'} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'space-between'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                    {speaker.name}
                </Text>
                <CloseButton color={'gray.500'} onClick={() => {removeSpeaker(index)}}/>
            </Flex>
        )
    }

    return (
        <>
            <Text color="gray.900" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                {episode.title}
            </Text>
            {speakers.length > 0 &&
                <Grid w={'full'} templateColumns='repeat(2, 3fr)' gap={2}>
                {speakers.map((speaker: SpeakerInvitation, index: number) => {
                    return (
                        <InputComponent key={speaker.name} speaker={speaker} index={index}/>
                    )
                })}
            </Grid>
            }
            <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FormControl w={'full'} isRequired={true} isInvalid={isInvalid}>
                    <InputGroup>
                        <Input
                            placeholder='Name'
                            w={'40%'}
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                        <InputRightAddon children='Add' onClick={addSpeaker} />
                    </InputGroup>
                    <FormErrorMessage>Please add at least one speaker.</FormErrorMessage>
                </FormControl>
            </Flex>
        </>
    )
}

export const MediaSpeakerInput = ({type, allSpeakers, setAllSpeakers}: {type: SpeakerType, allSpeakers: any, setAllSpeakers: any}) => {
    const [inputValue, setInputValue] = useState('');
    const [speakers, setSpeakers] = useState<SpeakerInvitation[]>([])
    const handleChange = (event: any) => {
        setInputValue(event.target.value)
    }
    const addSpeaker = () => {
        if (inputValue.length > 0) {
            let newSpeakers = [...speakers];
            let speaker: SpeakerInvitation = {
                name: inputValue,
                invited_as: type,
            }
            newSpeakers.push(speaker);
            setSpeakers(newSpeakers);
            setInputValue('');
            setAllSpeakers([...allSpeakers, ...newSpeakers])

        }
    }


    const removeSpeaker = (index: number, speaker: SpeakerInvitation) => {
        let newSpeakers = [...speakers]; // Create a copy
        let currentAllSpeakers = [...allSpeakers]
        newSpeakers.splice(index, 1);
        let indexToRemove = currentAllSpeakers.findIndex((s: SpeakerInvitation) => s.name === speaker.name)
        currentAllSpeakers.splice(indexToRemove, 1)
        setSpeakers(newSpeakers);
        setAllSpeakers(currentAllSpeakers)
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addSpeaker();
        }
    }

    const InputComponent = ({speaker, index}: { speaker: SpeakerInvitation, index: number}) => {
        return (
            <Flex w={'full'} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'space-between'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                    {speaker.name}
                </Text>
                <CloseButton color={'gray.500'} onClick={() => {removeSpeaker(index, speaker)}}/>
            </Flex>
        )
    }

    return (
        <>

            {speakers.length > 0 &&
                <Grid w={'full'} templateColumns='repeat(2, 3fr)' gap={2}>
                    {speakers.map((speaker: SpeakerInvitation, index: number) => {
                        return (
                            <InputComponent key={speaker.name} speaker={speaker} index={index}/>
                        )
                    })}
                </Grid>
            }
            <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FormControl w={'full'} isRequired={true}>
                    <InputGroup>
                        <Input
                            placeholder='Name'
                            w={'40%'}
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                        <InputRightAddon children='Add' onClick={addSpeaker} />
                    </InputGroup>
                    <FormErrorMessage>Please add at least one speaker.</FormErrorMessage>
                </FormControl>
            </Flex>
        </>
    )
}


