import {PodcastEpisode} from "../../models/PodcastEpisode";
import React, {useEffect, useState} from "react";
import {Flex, Grid, useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import {SearchLogo} from "../../assets/SearchIcon";
import {CustomPagination} from "../custom-pagination";
import {ShowCardSkeleton} from "../skeletons";
import {useUser} from "../../context/UserContext";
import {PodcastTranscriptionTaskMeta, TranscriptionSession} from "../../models/Localization";
import {EpisodeOngoingReviewItems, EpisodeReadyForReviewItems} from "./episode-review-card";

export const ReviewReadyModulePanel = ({episodes, podcastId, loading, state}: {episodes: PodcastEpisode[] | TranscriptionSession[], podcastId: string, loading: boolean, state: 'ready' | 'ongoing'}) => {
    const user: any = useUser()
    const [searchTerm, setSearchTerm] = useState('');
    const [displayStyle, setDisplayStyle] = useState<"grid" | "list">('grid')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [page, setPage] = useState(1); // initialize the current page to 1

    const dynamicPageSize = useBreakpointValue({ base: 12, md: 12, lg: 12 , xl: 8 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 8);

    useEffect(() => {
        setPageSize(dynamicPageSize || 8);
    }, [dynamicPageSize]);
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1)
    };
    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // calculate the start and end indices of the episodes to display on the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredEpisodes = (): PodcastEpisode[] => {
        if (state === 'ready') {
            return (episodes as PodcastEpisode[]).filter((episode) => {
                const { title } = episode;
                const normalizedSearchTerm = searchTerm.toLowerCase();
                return (
                    title.toLowerCase().includes(normalizedSearchTerm)
                );
            })
        } else {
            return []
        }
    }

    const filteredSessions = (): TranscriptionSession[] => {
        if (state === 'ongoing') {
            return (episodes as TranscriptionSession[]).filter((session) => {
                const { episode } = session as PodcastTranscriptionTaskMeta;
                const normalizedSearchTerm = searchTerm.toLowerCase();
                return (
                    episode.title.toLowerCase().includes(normalizedSearchTerm)
                );
            })
        } else {
            return []
        }
    }

    const updateDisplayStyle = (val: "grid" | "list") => {
        setDisplayStyle(val)
    }

    return (
        <Flex w={'full'} flexDirection={'column'} alignItems={'flex-start'} gap={'24px'}>
            <Flex w={'full'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Flex w={'33%'} justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                    <SearchLogo onType={handleSearchInputChange} onOpenWidth={'full'} />
                </Flex>
                <Flex w={'full'} justifyContent={'center'} alignItems={'center'} direction={'row'} gap={'12px'}>
                    <CustomPagination
                        totalPages={Math.ceil(filteredEpisodes.length / pageSize)}
                        currentPage={page}
                        onPageChange={setPage}
                    />
                </Flex>
                <Flex w={'33%'} alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
                </Flex>
            </Flex>
            <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
                {!loading?
                    <Grid w={'full'} templateColumns='repeat(1, 1fr)' gap={'24px'}>
                        {state === 'ready' &&
                            <>
                                {filteredEpisodes().slice(startIndex, endIndex).map((episode: PodcastEpisode) => (
                                    <EpisodeReadyForReviewItems key={`${episode.id}`} episode={episode}/>
                                ))}
                            </>
                        }
                        {state === 'ongoing' &&
                            <>
                                {filteredSessions().slice(startIndex, endIndex).map((session: TranscriptionSession) => (
                                    <EpisodeOngoingReviewItems key={`${session.id}`} session={session} />
                                ))}
                            </>
                        }
                    </Grid>
                    :
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {[...Array(2)].map((_, index) => (
                            <ShowCardSkeleton key={index}/>
                        ))}
                    </Grid>
                }
            </Flex>
        </Flex>
    )
}
