import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Badge, Divider, Flex, Group, Image, Paper, Text} from '@mantine/core';
import {useUser} from "../../context/UserContext";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";
import {usePodcastLocalization} from "../../context/PodcastLocalizationContext";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {PaymentStatus} from "../../models/Cart";
import {TranscriptionState} from "../../models/Localization";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {useDisclosure, useToast} from "@chakra-ui/react";
import {useLocalization} from "../../context/LocalizationContext";
import {PodcastLibrarySidebarRoutes, useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {useCart} from "../../hooks/useCartQueries";

export const EpisodeCard = ({
                                episode,
                                displayCheck,
                                isInMultiConfiguration
                            }: {
    episode: PodcastEpisode,
    displayCheck: "hidden" | "visible",
    isInMultiConfiguration?: boolean
}) => {
    const user: any = useUser();
    const { isEpisodeInCart, cart } = usePodcastLibrary();
    const { episodesConfiguration, setEpisodesConfiguration } = usePodcastLocalization();
    const [isInCart, setIsInCart] = useState<boolean>(false);
    const [waitingServerConfirmation, setWaitingServerConfirmation] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const navigate = useNavigate();
    const { podcastId } = useParams();
    const {currentPodcast} = usePodcastLibrary();
    const [hasBeenProcessed, setHasBeenProcessed] = useState<boolean>(false);

    useEffect(() => {
        if (episode.transcription.state !== TranscriptionState.NOT_CREATED) {
            setHasBeenProcessed(true);
        }
    }, [episode]);

    useEffect(() => {
        setIsInCart(isEpisodeInCart(episode.id));
        const cartItem = cart.find(item => item.episode.id === episode.id);
        setWaitingServerConfirmation(cartItem?.status === PaymentStatus.CHECKED_OUT);
    }, [cart, episode.id, isEpisodeInCart]);

    useEffect(() => {
        setIsChecked(isInMultiConfiguration || episodesConfiguration.has(episode.id));
    }, [isInMultiConfiguration, episodesConfiguration, episode.id]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConfiguration = new Map(episodesConfiguration);
        if (e.target.checked) {
            newConfiguration.set(episode.id, { episode });
        } else {
            newConfiguration.delete(episode.id);
        }
        setEpisodesConfiguration(newConfiguration);
    };

    const goToAiTranscript = () => {
        let sessionId = episode.transcription.session?.id;
        if (sessionId) {
            navigate(`/reader/${sessionId}/asr`);
        }
    };

    const LocalizationStatusCard = () => (
        <Flex w={'100%'} justify="space-between" align={'center'}>
            <Flex w={'100%'} p={'md'} direction="column" align="center" justify={'center'}>
                <Text c="gray" fw={700}>Transcription</Text>
                <Badge size="sm" variant="gradient" gradient={{ from: 'teal', to: 'green', deg: 0 }}>
                    {episode.transcription.state}
                </Badge>
            </Flex>
            <Divider orientation={'vertical'} />
            <Flex w={'100%'} p={'md'} direction="column" align="center" justify={'center'}>
                <Text c="gray" fw={700}>Translation</Text>
                <Badge size="sm" variant="gradient" gradient={{ from: 'teal', to: 'green', deg: 0 }}>
                    Not started
                </Badge>
            </Flex>
            <Divider orientation={'vertical'} />
            <Flex w={'100%'} p={'md'} direction="column" align="center" justify={'center'}>
                <Text c="gray" fw={700}>Dubbing</Text>
                <Badge size="sm" variant="gradient" gradient={{ from: 'teal', to: 'green', deg: 0 }}>
                    Not started
                </Badge>
            </Flex>
        </Flex>
    );

    return (
        <Flex direction="column" align="flex-start" gap="56px" style={{ alignSelf: 'stretch' }}>
            <Paper w={'100%'} radius="md" component="button" shadow={'sm'} withBorder onClick={goToAiTranscript}>
                <Flex align={'flex-start'} p={'md'} gap={'md'} direction={'column'}>
                    <Flex align={'flex-start'} gap={'md'} direction={'row'} justify={'flex-start'}>
                        <Image src={episode.image_url} radius="md" w={'100px'} h={'100px'}/>
                        <Group gap="2px" style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            <Text c="gray.6" fz="sm" fw={600} style={{textAlign: 'left'}}>
                                {episode.title}
                            </Text>
                            <Text c="gray.7" fz="sm">
                                {secondsToHHMMSS(episode.duration)}
                            </Text>
                            {displayCheck === 'visible' && (
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            )}
                            {isInCart && <Badge color="green">In Cart</Badge>}
                            {waitingServerConfirmation && <Badge color="yellow">Processing</Badge>}
                            {hasBeenProcessed && <Badge color="blue">Processed</Badge>}
                        </Group>
                    </Flex>
                    {hasBeenProcessed && <LocalizationStatusCard />}
                </Flex>
            </Paper>
        </Flex>
    );
};

export const SimpleEpisodeCard = ({ episode }: { episode: PodcastEpisode }) => {
    const libraryNavigation: any = useLibraryNavigation();
    const {currentPodcast} = usePodcastLibrary();
    const [isInCart, setIsInCart] = useState<boolean>(false);
    const localization: any = useLocalization()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { data: cartData = [] , isLoading: cartLoading, isError: cartError} = useCart()
    const [isItemInCart, setIsItemInCart] = useState<boolean>(false);
    const toast = useToast()

    useEffect(() => {
        if (!cartLoading && cartData.length > 0) {
            const itemInCart = cartData.find((item: any) => item.episode.id === episode.id);
            if (itemInCart) {
                setIsItemInCart(true);
            } else {
                setIsItemInCart(false);
            }
        }
    }, [cartLoading, cartData]);


    const handleOpenEpisodeSidebar = () => {
        if (!isItemInCart && episode.transcription.state === TranscriptionState.NOT_CREATED) {
            if (libraryNavigation.displayLibrarySidebar) {
                libraryNavigation.setRoute(PodcastLibrarySidebarRoutes.EPISODES)
                libraryNavigation.setCurrentEpisode(episode);
            } else {
                libraryNavigation.setRoute(PodcastLibrarySidebarRoutes.EPISODES)
                libraryNavigation.setCurrentEpisode(episode);
                libraryNavigation.setDisplaySidebar(true);
            }

        } else if (isItemInCart) {
            toast({
                title: 'Episode in Cart',
                description: 'The episode is already in your cart.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    return (
        <Flex direction="column" align="flex-start" gap="56px" style={{ alignSelf: 'stretch' }} onClick={handleOpenEpisodeSidebar}>
            <Paper w={'100%'} radius="md" component="button" shadow={'sm'} withBorder>
                <Flex align={'flex-start'} p={'md'} gap={'md'} direction={'column'}>
                    <Flex align={'flex-start'} gap={'md'} direction={'row'} justify={'flex-start'}>
                        <Image src={currentPodcast?.image_url} radius="md" w={'100px'} h={'100px'}/>
                        <Group gap="4px" style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            <Text c="gray.6" fz="sm" fw={600} style={{textAlign: 'left'}}>
                                {episode.title}
                            </Text>
                            <Text c="gray.7" fz="sm">
                                {secondsToHHMMSS(episode.duration)}
                            </Text>
                            {!!episode.transcription.processor &&
                                <Group gap="2px" style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start'
                            }}>
                                    <Badge size="sm" variant="gradient" gradient={{from: 'teal', to: 'green', deg: 0}}>
                                        {episode.transcription.processor}
                                    </Badge>
                        </Group>
                            }
                            <Flex align={'flex-start'} gap={'md'} direction={'row'} justify={'flex-start'}>
                                {isItemInCart && <Badge size={'sm'} color="orange">In Cart</Badge>}
                            </Flex>
                        </Group>
                    </Flex>
                </Flex>
            </Paper>
        </Flex>
    );
};
