import {
    Button,
    Flex,
    Grid, Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
    Text,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {MdAdd, MdFilterList, MdLibraryBooks} from "react-icons/md";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {useUser} from "../../context/UserContext";
import {SearchLogo} from "../../assets/SearchIcon";
import {CustomPagination} from "../../components/custom-pagination";
import {ShowCardSkeleton} from "../../components/skeletons";
import {LocalizationCard} from "../../components/episode/localization-card";
import ReviewDashboardPanel from "../../components/episode/review-dashboard-panel";
import {useGetTranscriptionSessions} from "../../hooks/useLocalizationQueries";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";

interface PostProductionLibraryProps {
    podcastId: string;
}
export const PodcastPostProductionLibrary: React.FC<PostProductionLibraryProps> = React.memo(({ podcastId }) => {
    const user: any = useUser()
    const {data: transcriptionSessions, isLoading: isLoadingTranscriptionSession, error: loadingError} = useGetTranscriptionSessions(podcastId)

    const [searchTerm, setSearchTerm] = useState('');
    const [stateFilter, setStateFilter] = useState<TranscriptionState>()
    const [currentSortingParam, setCurrentSortingParam] = useState<'title' | 'state'>('title')
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [transcriptionStates, setTranscriptionStates] = useState<Map<TranscriptionState, number>>(new Map())
    const sessions = useMemo(() => transcriptionSessions || [], [transcriptionSessions])
    const {setLocalizationTasks} = usePodcastLibrary()

    useEffect(() => {
        if (loadingError) {
            console.log(`loading error`)
        }
    }, [isLoadingTranscriptionSession, loadingError]);

    const [page, setPage] = useState(1); // initialize the current page to 1

    const { isOpen, onOpen, onClose } = useDisclosure()

    const dynamicPageSize = useBreakpointValue({ base: 4, md: 4, lg: 4 , xl: 4 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 4);

    const [transcriptionStateFilters, setTranscriptionStateFilters] = useState<Map<TranscriptionState, [string, string]>>(new Map())

    useEffect(() => {
        setPageSize(dynamicPageSize || 8);
    }, [dynamicPageSize]);

    useEffect(() => {
        if (isSearching) {
            setCurrentSortingParam('title')
        }
    }, [isSearching]);

    useEffect(() => {
        if (!isLoadingTranscriptionSession && sessions.length > 0) {
            let stateMap = new Map<TranscriptionState, number>()
            let stateFilters = new Map<TranscriptionState, [string, string]>()
            let sessionsMap = {}
            sessions.forEach((session: TranscriptionSession) => {
                if (stateMap.has(session.state)) {
                    stateMap.set(session.state, stateMap.get(session.state) as number + 1)
                } else {
                    stateMap.set(session.state, 1)
                }
                sessionsMap = {...sessionsMap, [session.id]: session}
            })
            stateFilters.set(TranscriptionState.SPEECH_TO_TEXT, ['Speech to Text: In Progress', 'yellow'])
            stateFilters.set(TranscriptionState.READY_FOR_PROOFREADING, ['Proofreading: Not Started', 'gray']);
            stateFilters.set(TranscriptionState.PROOFREADING, ['Proofreading: In Progress', 'yellow']);
            stateFilters.set(TranscriptionState.PROOFREADING_COMPLETED, ['Proofreading: Ready for next step', 'red']);
            stateFilters.set(TranscriptionState.EDITOR_REVIEW, ['Editor Review: Not Started', 'gray']);
            stateFilters.set(TranscriptionState.EDITOR_REVIEWING, ['Editor Review: In Progress', 'yellow']);
            stateFilters.set(TranscriptionState.PUBLISHER_REVIEWING, ['Publisher Review: In Progress', 'blue']);
            stateFilters.set(TranscriptionState.EDITOR_REVIEW_COMPLETED, ['Publisher Review: Not Started', 'red']);
            stateFilters.set(TranscriptionState.COMPLETED, ['Publisher Review: Completed', 'green']);

            setTranscriptionStates(stateMap)
            setTranscriptionStateFilters(stateFilters)
        }
    }, [sessions, isLoadingTranscriptionSession])

    useEffect(() => {
        if (sessions.length > 0 ) {
            let tasks: any[] = []
            sessions.forEach((session: TranscriptionSession) => {
                switch (session.state) {
                    case TranscriptionState.PROOFREADING_COMPLETED: {
                        tasks.push(session)
                        break;
                    }
                    case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                        tasks.push(session.id)
                        break;
                    }
            }})
            setLocalizationTasks(tasks)
        }
    }, [sessions]);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1)
    };

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredSessions = useMemo(() => {
        return sessions.filter((session: TranscriptionSession) => {
            const { title } = (session as PodcastTranscriptionTaskMeta).episode;
            const normalizedSearchTerm = searchTerm.toLowerCase();

            switch (currentSortingParam) {
                case 'title': {
                    return (
                        title.toLowerCase().includes(normalizedSearchTerm)
                    );
                }
                case 'state': {
                    return (
                        session.state === stateFilter
                    );
                }
            }
        });
    }, [sessions, searchTerm, stateFilter, currentSortingParam]);

    return (
        <Flex
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="md"
            p="12px"
            w="full"
            h={'full'}
            style={{overflowY: 'auto' }}
        >
            <Flex w={'full'} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Flex w={'33%'} justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                    <SearchLogo onType={handleSearchInputChange} onOpenWidth={'full'} setIsSearching={setIsSearching}/>
                </Flex>
                <Flex w={'full'} justifyContent={'center'} alignItems={'center'} direction={'row'} gap={'12px'}>
                    <CustomPagination
                        totalPages={Math.ceil(filteredSessions.length / pageSize)}
                        currentPage={page}
                        onPageChange={setPage}
                    />
                </Flex>
                <Flex w={'33%'} gap={'8px'} alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
                    <Button onClick={onOpen} bgColor={'teal.500'} _hover={{bgColor: 'teal.600'}} h={'36px'} borderRadius={'6px'} borderColor={'gray.500'} size="lg" variant={'solid'}>
                        <Text color={'white'} fontSize={'16px'} fontFamily={'sans-serif'} fontWeight={'600px'}>Manage Reviews</Text>
                    </Button>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Filter'
                            icon={<MdFilterList />}
                            variant='outline'
                        />
                        {!isLoadingTranscriptionSession && sessions.length > 0 &&
                            <MenuList>
                                <MenuItem gap={'12px'} onClick={() => {setCurrentSortingParam('title')}}>
                                    <Tag size="md" variant="solid" colorScheme={'gray'}>
                                        All {sessions.length}
                                    </Tag>
                                </MenuItem>
                                {Array.from(transcriptionStateFilters).map(([state, label]) => (
                                        <MenuItem gap={'12px'} onClick={() => {
                                            setStateFilter(state)
                                            setIsSearching(false)
                                            setPage(1)
                                            setCurrentSortingParam('state')
                                        }}>
                                            <Tag key={state} size="md" variant="solid" colorScheme={label[1]}>
                                                {label[0]} {transcriptionStates.get(state as TranscriptionState)? transcriptionStates.get(state as TranscriptionState) : 0}
                                            </Tag>
                                        </MenuItem>
                                ))}
                            </MenuList>
                        }
                    </Menu>
                </Flex>
            </Flex>
            <Flex w="full" h={'full'} flexDirection="column" alignItems={'flex-start'} style={{overflowY: 'auto' }}>
                {!isLoadingTranscriptionSession && sessions.length > 0 &&
                    <Grid w={'full'} templateColumns='repeat(1, 1fr)' gap={'24px'}>
                        {filteredSessions.slice(startIndex, endIndex).map((session: TranscriptionSession) => (
                            <LocalizationCard session={session}/>
                        ))}
                    </Grid>
                }
                {!isLoadingTranscriptionSession && sessions.length === 0 &&
                    <Flex w={'full'} direction={'column'} alignItems={'center'} justifyContent={'center'} py={12}>
                        <Icon as={MdLibraryBooks} boxSize={16} color={'gray.400'} mb={4} />
                        <Text fontSize={'24px'} fontWeight={'bold'} color={'gray.700'} mb={2}>
                            No localization jobs found.
                        </Text>
                        <Text fontSize={'18px'} color={'gray.500'} textAlign={'center'} mb={6}>
                            You haven't localized any episodes yet. Start by adding your first episode!
                        </Text>
                        <Button
                            leftIcon={<MdAdd />}
                            colorScheme={'teal'}
                            size={'lg'}
                            fontWeight={'semibold'}
                        >
                            Configure episode
                        </Button>
                    </Flex>

                }
                {isLoadingTranscriptionSession &&
                    <Grid w={'full'} templateColumns='repeat(1, 1fr)' gap={'24px'}>
                        <ShowCardSkeleton/>
                        <ShowCardSkeleton/>
                        <ShowCardSkeleton/>
                    </Grid>
                }
            </Flex>
            <ReviewDashboardPanel isOpen={isOpen} onOpen={onOpen} onClose={onClose} podcastId={podcastId} />
        </Flex>
    )
})
