import {Drawer, DrawerContent, DrawerOverlay} from "@chakra-ui/modal";
import {
    CloseButton,
    Flex,
    Icon,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast
} from "@chakra-ui/react";
import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {GrMore} from "react-icons/gr";
import {Tab} from "../ui-utils";
import 'react-circular-progressbar/dist/styles.css';
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {ReviewReadyModulePanel} from "./review-module-panel";
import {useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {usePodcastLibrary} from "../../context/PodcastLibraryContext";

export default function ReviewDashboardPanel({isOpen, onOpen, onClose, podcastId}: {isOpen: boolean, onOpen: any, onClose: any, podcastId: string}) {
    const auth: any = useAuth()
    const user: any = useUser()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [requestLoading, setRequestLoading] = useState<boolean>(true)
    const [readyForReviewEpisodes, setReadyForReviewEpisodes] = useState<PodcastEpisode[]>([])
    const [episodeInReview, setEpisodeInReview] = useState<TranscriptionSession[]>([])
    const episodesInReviewReq = useApi('/publisher/review/ongoing/get/DeJywyienIQBwckWsjOm3JdMH3t1', user.token, true)
    const toast: any = useToast()
    const {currentPodcastEpisodes} = usePodcastLibrary()


    useEffect(() => {
        if (currentPodcastEpisodes) {
            let episodes = currentPodcastEpisodes
            let ready: PodcastEpisode[] = []
            if (episodes) {
                for (let i = 0; i < episodes.length; i++) {
                    let episode = episodes[i]
                    if (episode.transcription.state === TranscriptionState.PROOFREADING_COMPLETED) {
                        ready.push(episode)
                    }
                }
            }

            setReadyForReviewEpisodes(ready)
            setLoading(false)
        }

    }, [currentPodcastEpisodes])

    useEffect(() => {
        if (!episodesInReviewReq.loading && episodesInReviewReq.data) {
            setEpisodeInReview(episodesInReviewReq.data)
            setRequestLoading(false)
        } else if (!episodesInReviewReq.loading && episodesInReviewReq.error) {
            console.log(`REVIEW_DASHBOARD_PANEL -> Unable to get ongoing review sessions`)
            toast({
                title: 'Unable to get ongoing review sessions',
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        }
    }, [episodesInReviewReq.loading])
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    return (
        <Drawer isOpen={isOpen} onClose={() => {
        }}
                size={'lg'}>
            <DrawerOverlay />
            <DrawerContent w={'full'} h={'100vh'} boxShadow="-4px 0px 10px 0px rgba(0, 0, 0, 0.06)">
                <Flex w="full" h={'full'} px={"2%"} pt={'2%'} pb={'10%'} flexDirection="column" alignItems="flex-start" gap="24px">
                    <Flex w="full" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <CloseButton onClick={() => {
                            onClose()
                        }} />
                        <Text color="gray.700" textAlign="center" isTruncated fontSize="lg" fontStyle="normal" fontWeight="medium" lineHeight="7">
                            Editor Review Dashboard
                        </Text>
                        <>
                            <Icon as={GrMore} visibility={'hidden'} />
                        </>
                    </Flex>
                    <Tabs w={'full'} style={{overflow: 'auto'}} position={'relative'} _selected={{textColor:'teal.500'}} align={'center'} isLazy={true} index={tabIndex} onChange={handleTabsChange} size={'lg'} variant={'unstyled'}>
                        <TabList>
                            <Tab>
                                <Text>
                                    Ready for Review ({readyForReviewEpisodes.length})
                                </Text>
                            </Tab>
                            <Tab>
                                <Text>
                                    In Review ({episodeInReview.length})
                                </Text>
                            </Tab>
                        </TabList>
                        <TabIndicator pos={'fixed'} height="2px" bg="teal.500"/>
                        <TabPanels w={'full'} h={'full'} mt={'36px'} style={{overflow: 'auto'}}>
                            <TabPanel p={'0px'}>
                                {readyForReviewEpisodes.length > 0?
                                    <Flex w={'full'} h={'full'} style={{overflow: 'auto'}} p="16px" flexDirection="column" alignItems="flex-start" gap="20px" borderRadius="12px" border="1px" borderColor="gray.300">
                                        <ReviewReadyModulePanel episodes={readyForReviewEpisodes as PodcastEpisode[]} podcastId={podcastId} loading={loading} state={'ready'}/>
                                    </Flex>
                                    :
                                    null
                                }
                            </TabPanel>
                            <TabPanel p={'0px'}>
                                {episodeInReview.length > 0?
                                    <Flex w={'full'} h={'full'} style={{overflow: 'auto'}} p="16px" flexDirection="column" alignItems="flex-start" gap="20px" borderRadius="12px" border="1px" borderColor="gray.300">
                                        <ReviewReadyModulePanel episodes={episodeInReview as TranscriptionSession[]} podcastId={podcastId} loading={requestLoading} state={'ongoing'}/>
                                    </Flex>
                                    :
                                    null
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}
