import {Subscription, SupportedLanguages} from "../models/AppConfig";
import {BraincapService} from "../models/Localization";
import apiClient, {PODCASTLIBRARYENDPOINTS, STUDIOENDPOINTS} from "../api/braincap-api-client";

export const LocalizationService = {
    getAppConfig: async (): Promise<{
        languages: SupportedLanguages,
        services: { [key: string]: BraincapService[] },
        subscriptions: Subscription[]
    }> => {
        const response = await apiClient.get(PODCASTLIBRARYENDPOINTS.APP_CONFIG);
        return response.data;
    },

    getTranscriptionSessions: async (podcastId: string): Promise<any> => {
        console.log(`fetching transcription sessions for podcastId: ${podcastId}`)
        const response = await apiClient.get(`${STUDIOENDPOINTS.GET_TRANSCRIPTION_SESSIONS}/${podcastId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`Error getting transcription sessions: ${response.data}`);
            return Promise.reject(response.data);
        }
    },

    getAsrTranscript: async (sessionId: string): Promise<any> => {
        const response = await apiClient.get(`/transcription/transcript/get/${sessionId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`Error getting transcription sessions: ${response.data}`);
            return Promise.reject(response.data);
        }
    },

    getProofreadTranscript: async (sessionId: string): Promise<any> => {
        const response = await apiClient.get(`/transcription/transcript/proofread/get/${sessionId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`Error getting transcription sessions: ${response.data}`);
            return Promise.reject(response.data);
        }
    },

    getFinalTranscript: async (sessionId: string): Promise<any> => {
        const response = await apiClient.get(`/transcription/transcript/get/${sessionId}`);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error(`Error getting transcription sessions: ${response.data}`);
            return Promise.reject(response.data);
        }
    }
};
