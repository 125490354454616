import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {EpisodeModule} from "../../components/episode/episode-module";
import CartPanel from "../../components/cart/cart-panel";
import {RssLocalizationProgress} from "../../components/progress-components";
import {SegmentedControl, Flex, Text} from "@mantine/core";
import {PodcastPostProductionLibrary} from "./PodcastPostProductionLibrary";

export enum LocalizationProgressFilter {
    ALL = 'All',
    TRANSCRIPTION_COMPLETED = 'Transcription Completed',
    TRANSCRIPTION_IN_PROGRESS = 'Transcription In Progress',
    TRANSCRIPTION_NOT_STARTED = 'Transcription Not Started',
    TRANSLATION_COMPLETED = 'Translation Completed',
    TRANSLATION_IN_PROGRESS = 'Translation In Progress',
    TRANSLATION_NOT_STARTED = 'Translation Not Started',
    DUBBING_COMPLETED = 'Dubbing Completed',
    DUBBING_IN_PROGRESS = 'Dubbing In Progress',
    DUBBING_NOT_STARTED = 'Dubbing Not Started',
}
export default function PodcastDashboard() {
    const { podcastId } = useParams<{ podcastId: string }>();
    const [activeTab, setActiveTab] = useState('episodes');
    const [activeFilter, setActiveFilter] = useState<LocalizationProgressFilter>(LocalizationProgressFilter.ALL);


    if (!podcastId) {
        return <div>Loading...</div>;
    }

    return (
        <Flex w={'100%'} h={'100%'} direction={'column'} gap={'16px'}>
            <RssLocalizationProgress setProgressFilter={setActiveFilter}/>
            <Flex           direction="column"
                            align="flex-start"
                            gap="24px"
                            p="12px"
                            w="100%"
                            h={'100%'}
                            styles={(theme) => ({
                                root: {
                                    backgroundColor: 'white',
                                    borderRadius: '12px',
                                    border: `1px solid ${theme.colors.gray[3]}`,
                                },
                            })}
                            style={{ alignSelf: 'stretch' }}>
                <Text style={{ wordBreak: 'break-word', color: '#2C3E50', fontSize: '20px', fontWeight: 700, lineHeight: '24px' }}>
                    Library
                </Text>
                <Flex w={'100%'} justify={'flex-start'} align={'flex-start'} gap={'16px'}>
                    <SegmentedControl
                        w={'100%'}
                        h={'fit-content'}
                        value={activeTab}
                        color={'#319795'}
                        size={'md'}
                        onChange={setActiveTab}
                        data={[
                            { label: 'Episodes', value: 'episodes' },
                            { label: 'Localization', value: 'localization' },
                        ]}
                        styles={{
                            root: {
                                backgroundColor: 'white',
                                borderRadius: '12px',
                                borderBottom: '1px solid #E2E8F0',
                                border: '1px solid #E2E8F0',
                                padding: '10px 10px',
                            },

                        }}
                    />
                </Flex>
                <Flex w={'100%'} h={'100%'} justify={'flex-start'} align={'flex-start'} gap={'16px'}             style={{overflowY: 'auto' }}
                >
                    {activeTab === 'episodes' && <EpisodeModule podcastId={podcastId} progressFilter={activeFilter} />}
                    {activeTab === 'localization' && <PodcastPostProductionLibrary podcastId={podcastId}/>}
                </Flex>
            </Flex>
        </Flex>
    );
}
